:root {
  --container-color: #eeeeee;
  --sidebar-color: #fff;
  --primary-color: #d69a33;
  --primary-color-light: #f1e4d1;
  --toggle-color: #ddd;
  --text-color: #2e2e2e;
  --cell-color: #f2f2f2;

  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

.dash-container {
  min-height: 100vh;
  background-color: var(--container-color);
  transition: var(--tran-05);
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

.dash-container.dark {
  --container-color: #fed692;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
  --cell-color: #242526;
}

/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  z-index: 100;
}

.sidebar.close {
  width: 88px;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}

.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
  color: var(--text-color);
  transition: var(--tran-03);
}

.sidebar .text {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}

.sidebar.close .text {
  opacity: 0;
}

/* =========================== */

.sidebar header {
  position: relative;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}

.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
}

header .image-text .name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
}

header .image-text .profession {
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

.add {
  height: 20px;
  width: 20px;
  background-color: #d69a33;
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
}

.dash-container.dark .sidebar header .toggle {
  color: var(--text-color);
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
  margin-top: 40px;
}

.sidebar li.dashboard {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar li button {
  list-style: none;
  height: 100%;
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
}

.sidebar li button:hover {
  background-color: var(--primary-color);
}

.sidebar li button:hover .icon,
.sidebar li button:hover .text {
  color: var(--sidebar-color);
}

.dash-container.dark .sidebar li button:hover .icon,
.dash-container.dark .sidebar li button:hover .text {
  color: var(--text-color);
}

.sidebar .menu-bar {
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
  display: none;
}

.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
  height: 50px;
  width: 60px;
}

.mode .sun-moon i {
  position: absolute;
}

.mode .sun-moon i.sun {
  opacity: 0;
}

.dash-container.dark .mode .sun-moon i.sun {
  opacity: 1;
}

.dash-container.dark .mode .sun-moon i.moon {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

.dash-container.dark .switch::before {
  left: 20px;
}

.home {
  position: absolute;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  background-color: var(--container-color);
  transition: var(--tran-05);
}

.home .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
}

.dash-container.dark .home .text {
  color: var(--text-color);
}

.data {
  padding: 2rem;
}

.MuiPaper-root {
  background-color: var(--sidebar-color) !important;
  color: var(--text-color) !important;
}

.MuiInput-underline::before {
  border-bottom: 1px solid var(--text-color) !important;
}

.MuiInput-underline::after {
  border-bottom: 1px solid var(--text-color) !important;
}

input,
.MuiButtonBase-root,
.MuiTableSortLabel-root,
.MuiTableCell-body,
.MuiTypography-caption,
.MuiInputBase-input,
.MuiTypography-h6,
.MuiSvgIcon-root {
  color: var(--text-color) !important;
}

h6.MuiTypography-root.MuiTypography-h6 {
  color: #ff5c35 !important;
}

@media (max-width: 450px) {
  html {
    font-size: 60% !important;
  }

  .sidebar {
    top: 0;
    height: 100%;
    position: fixed;
    padding: 0;
    width: 40px;
  }

  .sidebar .text {
    display: none;
    opacity: 0;
  }

  .sidebar .icon {
    min-width: 40px;
    text-align: center;
  }

  .sidebar.close {
    width: 40px;
    padding: 0;
    margin: 0;
  }

  .sidebar.close ~ .home {
    left: 25px;
    width: 100%;
  }

  .sidebar header .toggle {
    display: none;
  }

  .sidebar header .image img {
    width: 25px;
    border-radius: 6px;
    margin-right: 20px;
  }

  .home {
    position: absolute;
    top: 0;
    left: 25px;
    width: 100%;
    background-color: var(--container-color);
    transition: var(--tran-05);
  }

  .sidebar .menu {
    width: 40px;
  }

  .create-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: -webkit-repeating-linear-gradient(
      -45deg,
      #d69a33,
      #684235,
      #d69a33,
      #684235
    );
    background-size: 400%;
    padding: 20px;
  }

  .create-wrapper {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
  }

  .create-content > ul > input {
    font-size: 1.2rem;
  }

  .create-content > p {
    font-size: 1.2rem;
  }

  .create-details button,
  .share-button {
    border: none;
    outline: none;
    color: rgb(53, 53, 53);
    font-size: 1rem;
    cursor: pointer;
    padding: 7px 13px;
    border-radius: 5px;
    background: #d69a33;
    transition: 0.3s ease;
  }
  .create-details button:hover {
    background: #e6a639;
  }

  .upload-wrapper {
    border-radius: 5px;
    padding: 30px;
  }
  .upload-wrapper header {
    color: #2c2c2c;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  form i {
    font-size: 40px;
  }
  form p {
    margin-top: 15px;
    font-size: 1.2rem !important;
  }

  .duration {
    position: absolute;
    left: 50% !important;
    top: 10% !important;
    outline: "none";
    font-size: 1.2rem !important;
  }

  .data {
    padding: 0;
  }

  .menu-bar .bottom-content .toggle-switch {
    min-width: 30px;
  }

  .create-wrapper {
    width: 100%;
  }

  .wrapper {
    width: 100%;
    position: absolute;
  }

  .add {
    height: 15px;
    width: 15px;
    font-size: 15px;
    z-index: 99999;
  }
}
