.create-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    #d69a33,
    #684235,
    #d69a33,
    #684235
  );
  background-size: 400%;
  overflow: hidden;
}

.create-wrapper {
  width: 700px;
  background: #fff;
  border-radius: 10px;
  padding: 18px 25px 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
}
.create-wrapper :where(.create-title, li, li i, .create-details) {
  display: flex;
  align-items: center;
}
.create-title img {
  max-width: 21px;
}
.create-title h2 {
  font-size: 21px;
  font-weight: 600;
  margin-left: 8px;
}
.create-wrapper .create-content {
  margin: 10px 0;
}
.create-content p {
  font-size: 15px;
}
.create-content ul {
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
  margin: 12px 0;
  border-radius: 5px;
  border: 1px solid #a6a6a6;
}
.create-content ul li {
  color: #333;
  margin: 4px 3px;
  list-style: none;
  border-radius: 5px;
  background: #f2f2f2;
  padding: 5px 8px 5px 10px;
  border: 1px solid #e3e1e1;
  text-transform: none;
}
.create-content ul li i {
  height: 20px;
  width: 20px;
  color: #808080;
  margin-left: 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
  background: #dfdfdf;
  justify-content: center;
}
.create-content ul input {
  flex: 1;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 16px;
  text-transform: none;
}
.create-wrapper .create-details {
  justify-content: space-around;
}
.create-details button {
  border: none;
  outline: none;
  color: rgb(53, 53, 53);
  font-size: 14px;
  cursor: pointer;
  padding: 9px 15px;
  border-radius: 5px;
  background: #d69a33;
  transition: 0.3s ease;
}
.create-details button:hover {
  background: #e6a639;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar a {
  color: inherit;
  text-decoration: none;
}

.upload-wrapper {
  /* width: 430px; */
  border-radius: 5px;
  padding: 30px;
}
.upload-wrapper header {
  color: #2c2c2c;

  font-size: 27px;
  font-weight: 600;
  text-align: center;
}
.upload-wrapper form {
  height: 167px;
  display: flex;
  cursor: pointer;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #2c2c2c;
}
form :where(i) {
  color: #d69a33;
}
form i {
  font-size: 70px;
}
form p {
  margin-top: 15px;
  font-size: 16px;
}

section .row {
  margin-bottom: 10px;
  background: #ffe9c4;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section .row i {
  color: #d69a33;
  font-size: 30px;
}
section .details span {
  font-size: 14px;
}
.progress-area .row .content {
  width: 100%;
  margin-left: 15px;
}
.progress-area .details {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
}
.progress-area .content .progress-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}
.content .progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #d69a33;
  border-radius: inherit;
}
.uploaded-area {
  max-height: 232px;
  overflow-y: scroll;
}
.uploaded-area.onprogress {
  max-height: 150px;
}
.uploaded-area::-webkit-scrollbar {
  width: 0px;
}
.uploaded-area .row .content {
  display: flex;
  align-items: center;
}
.uploaded-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.uploaded-area .row .details .size {
  color: #404040;
  font-size: 11px;
}
.uploaded-area i.fa-check {
  font-size: 16px;
}
