.create-container .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-wrapper .field {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.field.active {
  border-color: #d69a33;
}

.field input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.field i {
  width: 50px;
  font-size: 18px;
  text-align: center;
}

.field.active i {
  color: #d69a33;
}

.field button,
.share-button {
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: background 0.1s linear, border-color 0.1s linear,
    color 0.1s linear;
  color: rgb(49, 49, 49);
  padding: 5px 18px;
  background: #d69a33;
  overflow-wrap: normal;
}

.field button:hover {
  background: #d69a20;
}
