@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

.container {
  display: grid;
  height: 100vh;
  width: 100%;
  place-items: center;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    #d69a33,
    #684235,
    #d69a33,
    #684235
  );
  background-size: 400%;
  overflow: hidden;
}

.wrapper {
  overflow: hidden;
  max-width: 390px;
  background: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}

.wrapper .title-text {
  display: flex;
  width: 200%;
}

.wrapper .title {
  width: 50%;
  font-size: 25px;
  letter-spacing: 1px;
  color: rgb(41, 41, 41);
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: rgb(41, 41, 41);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}

.slide-controls label.signup {
  color: rgb(41, 41, 41);
}

.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 5px;
  background: -webkit-linear-gradient(left, #fcc201, #ffd70e);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"] {
  display: none;
}

#signup:checked ~ .slider-tab {
  left: 50%;
}

#signup:checked ~ label.signup {
  color: rgb(41, 41, 41);
  cursor: default;
  user-select: none;
}

#signup:checked ~ label.login {
  color: rgb(41, 41, 41);
}

#login:checked ~ label.signup {
  color: rgb(41, 41, 41);
}

#login:checked ~ label.login {
  cursor: default;
  user-select: none;
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}

.wrapper .logo {
  width: 100%;
  height: 100%;
  text-align: center;
}

.wrapper .logo img {
  width: 50px;
  object-fit: cover;
}

.form-container .form-inner {
  display: flex;
  width: 100%;
}

.form-container .form-inner form {
  width: 300px;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 15px;
}

.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}

.form-inner form .field input:focus {
  border-color: #ffd29d;
}

.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

form .field input:focus::placeholder {
  color: #b3b3b3;
}

.form-inner form .pass-link {
  margin-top: 5px;
}

.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
}

.form-inner form .pass-link a,
.form-inner form .signup-link a {
  color: #ffd70e;
  text-decoration: none;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}

form .btn {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

form .btn .btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  background: -webkit-linear-gradient(
    right,
    #fcc201,
    #ffd70e,
    #fcc201,
    #ffd70e
  );
  border-radius: 5px;
  transition: all 0.4s ease;
}

form .btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: rgb(41, 41, 41);
  padding-left: 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.margin-fifty {
  margin-left: -50%;
}

.margin-zero {
  margin-left: 0;
}

[data-rbd-drag-handle-context-id="0"] {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
  overflow-wrap: normal !important;
}

h3 {
  overflow-wrap: normal !important;
}

.emphasis {
  font-weight: 700 !important;
}
